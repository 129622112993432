(function () {
  'use strict';

  angular
  .module('neo.authentication.registration')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('authentication.registration', {
      params: {
        token: null
      },
      templateUrl: 'authentication/registration/registration.tpl.html',
      controller: 'RegistrationCtrl',
      controllerAs: 'vm'
    });
  }
}());
